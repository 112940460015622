<template>
  <div>
    <div class="school" v-if="$store.state.pc">
      <div class="head">
        <h1 v-if="this.$route.path == '/nhwx'">{{ themeOther.name }}</h1>
        <div style="display: none">{{ themeOther }}</div>
        <div class="block" v-if="bannerList && bannerList.length >= 2">
          <el-carousel height="300px">
            <el-carousel-item v-for="(item, index) in bannerList" :key="index">
              <img :src="item.url" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>

        <div class="banner" v-else>
          <img :src="bannerList[0].url" alt="" v-if="bannerList && bannerList[0]" />
        </div>
      </div>
      <div class="content">
        <div class="nav">
          <el-row>
            <el-col :span="6" v-for="(item, index) in themeData" :key="index">
              <div @click="tabs(index)" class="grid-content bg-purple"
                :class="[currentIndex === index ? 'grid-content-current' : '']">
                {{ item.name }}
              </div>
            </el-col>
          </el-row>
        </div>
        <p><span>项目列表</span></p>
        <div class="list">
          <div class="item" v-for="(item, index) in themeData[currentIndex].children" :key="index">
            <div class="item_left">{{ index + 1 }}</div>
            <div class="item_center">
              {{ item.name }}
            </div>
            <div class="item_right" @click="check(item)">
              <span>查看详情 <i class="el-icon-right" style="cursor: pointer"></i></span>
            </div>
          </div>
        </div>
      </div>
      <div class="foot">
        <basecopyRight></basecopyRight>
      </div>
    </div>
    <div class="mob" v-else>
      <div class="head">
        <!--<h1>{{ themeOther.name }}</h1>-->
        <div style="display: none">{{ themeOther }}</div>
        <div class="block" v-if="bannerList && bannerList.length >= 2">
          <el-carousel height="200px">
            <el-carousel-item v-for="(item, index) in bannerList" :key="index">
              <img :src="item.url" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>

        <div class="banner" v-else>
          <img :src="bannerList[0].url" alt="" v-if="bannerList && bannerList[0]" />
        </div>
      </div>
      <div class="content1" v-if="show">
        <ul>
          <li :class="[currentIndex == index ? 'currnet-li' : '']" @click="goContent(item, index)"
            v-for="(item, index) in themeData" :key="index">
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="content2" v-else>
        <div class="title">
          <div @click="toList">
            <p>{{ themeData[currentIndex].name }}</p>
            <p><van-icon name="arrow-down" /></p>
          </div>
          <div class="show">
            <p><span>项目列表</span></p>
            <div class="list" v-if="themeData[currentIndex]">
              <div class="item" v-for="(item, index) in themeData[currentIndex].children" :key="index">
                <div class="item_left">{{ index + 1 }}</div>
                <div class="item_center">
                  {{ item.name }}
                </div>
                <div class="item_right" @click="toHome(item)">
                  <span> <i class="el-icon-arrow-right"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { zhuanlan_index, banner_list, twice_list } from "../../api/api";
import basecopyRight from "../../components/Public/BasecopyRight/basecopyRight";
export default {
  components: {
    basecopyRight,
  },
  data() {
    return {
      themeOther: JSON.parse(localStorage.getItem("themeOther")),
      themeData: JSON.parse(localStorage.getItem("themeData")),
      bannerList: [], //banner数据
      currentIndex: 0, //nav下标
      show: true,
    };
  },
  created() {
    // 主题色
    console.log(123);
    this.getbanner();

    // this.color();
  },
  methods: {
    // color() {

    //   if (localStorage.getItem('themeOther') && localStorage.getItem('themeOther').color) {

    //     document
    //       .getElementsByTagName("body")[0]
    //       .style.setProperty("--test", localStorage.getItem('themeOther').color);
    //   }

    // },

    toList() {
      this.show = !this.show;
    },
    goContent(item, index) {
      this.show = !this.show;
      this.currentIndex = index;
    },
    toHome(item) {
      this.$router.push({
        path: `/${localStorage.getItem("urlL")}/Two`,
        query: {
          project_id: item.id,
          name: item.name,
        },
      });
    },
    check(item) {
      console.log(item);
      this.$store.state.title2 = item.title2;
      this.$router.push({
        path: `/${localStorage.getItem("urlL")}/Two`,
        query: {
          project_id: item.id,
          name: item.name,
        },
      });
    },
    getbanner() {
      banner_list({ zl_url: localStorage.getItem("urlL") }).then((res) => {
        this.bannerList = res.data.data;
      });
    },
    // tabs切换
    tabs(index) {
      this.currentIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/default/default.scss";

.pc {
  .school {
    box-sizing: border-box;

    .head {
      position: relative;
      width: 100%;
      height: 300px;

      h1 {
        font-size: 40px;
        color: white;
        z-index: 99;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: 0;
        transform: translate(-50%);
      }

      p {
        position: absolute;
        font-size: 40px;
        margin: 0;
        color: white;
        top: 40%;
        left: 50%;
        transform: translateX(-50%);
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .content {
      margin: 0 auto;
      margin-top: 30px;
      width: 1400px;

      .nav {
        .grid-content {
          width: 350px;
          box-shadow: 0px 0px 6px 0px rgba(64, 64, 64, 0.1);
          height: 100px;
          color: #999999;
          line-height: 100px;
          text-align: center;
          background: white;
          cursor: pointer;
          font-size: 16px;
        }

        .grid-content-current {
          cursor: pointer;
          background: $blue;
          color: white;
        }
      }

      p {
        margin-top: 30px;

        span {
          padding-left: 15px;
          color: $blue;
          font-size: 20px;
          font-weight: bold;
        }
      }

      .list {
        min-height: 400px;

        .item {
          border: 2px solid #e5efff;
          background: white;
          box-shadow: 0px 0px 6px 0px rgba(64, 64, 64, 0.1);
          height: 70px;
          display: flex;
          margin-bottom: 15px;
          line-height: 70px;
          text-align: center;

          .item_left {
            flex: 1;
            color: $blue;
            font-weight: bold;
            background: #e5efff;
          }

          .item_center {
            text-align: left;
            flex: 7;
            padding-left: 20px;
          }

          .item_right {
            cursor: pointer;
            color: $blue;
            font-weight: bold;
            flex: 2;
          }
        }
      }
    }
  }
}

.mob {
  background: white;
  box-sizing: border-box;

  .head {
    position: relative;
    width: 100%;
    height: 18.75rem;
    position: relative;

    .banner {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .block {
      width: 100%;
      height: 18.75rem;

      img {
        width: 100%;
        height: 100%;
      }
    }

    h1 {
      margin: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%);
    }

    img {
      width: 100%;
      height: 100%;
    }

    p {
      position: absolute;
      margin: 0;
      text-align: center;
      left: 50%;
      top: 50%;
      font-size: 2rem;
      transform: translate(-50%);
      color: white;
    }
  }

  .content1 {
    margin-top: 1.875rem;
    padding: 0 15px;

    ul {
      display: flex;
      flex-wrap: wrap;
      color: #999999;
      justify-content: space-between;
      text-align: center;
      line-height: 9.875rem;
      width: 100%;
      margin: 0;
      font-weight: 400;
      font-size: 1.5rem;
      padding: 0;

      li {
        margin-bottom: 10px;
        width: 47%;
        height: 9.875rem;
        border-radius: 10px;
        border: 2px solid #f1f1f1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .currnet-li {
        background: $blue;
        color: white;
      }
    }
  }

  .content2 {
    .title {
      width: 100%;
      background: $blue;
      color: white;
      box-shadow: 0px 0px 16px 0px rgba(51, 51, 51, 0.1);
      font-size: 2.1rem;
      height: 8.125rem;

      .show {
        box-sizing: border-box;
        padding: 0 15px;
        width: 100%;
        margin-top: 3.125rem;

        p {
          color: $blue;
          font-size: 2.25rem;
          text-align: left;
        }

        .list {
          .item {
            background: white;
            box-shadow: 0px 0px 6px 0px rgba(64, 64, 64, 0.1);
            height: 70px;
            display: flex;
            margin-bottom: 15px;
            line-height: 70px;
            text-align: center;

            .item_left {
              flex: 1;
              color: $blue;
              font-weight: bold;
              background: #e5efff;
              font-size: 1.875rem;
            }

            .item_center {
              text-align: left;
              flex: 7;
              color: #333333;
              font-size: 1rem;
              padding-left: 20px;
            }

            .item_right {
              cursor: pointer;

              color: $blue;
              font-weight: bold;
              font-size: 1.875rem;
              flex: 2;
            }
          }
        }
      }

      p {
        text-align: center;
        margin: 0;

        &:nth-child(1) {
          padding-top: 20px;
        }
      }
    }
  }

  .foot {}
}

html,
body {
  width: 100%;
  height: 100%;
  background: white;
}
</style>
